import React from 'react';
import { MediaBox, MediaBoxes, SectionTitle } from '@2020-contest-web/components/src/components';

export function MediaSection() {
    return (
        <section className="section has-background-white-bis">
            <div className="container" style={{ maxWidth: 822 }}>
                <SectionTitle className="title" style={{ marginBottom: 30 }} title={'MEDIA'} subtitle={<>掲載メディア</>} />
                <MediaBoxes>
                    <div style={{ maxWidth: 250 }}>
                        <MediaBox
                            href="https://mainichikirei.jp/article/20191116dog00m100006000c.html"
                            src="https://storage.mainichikirei.jp/images/2019/11/16/20191116dog00m100006000c/001_size6.jpg"
                            title="“日本一の大学サークル美人”に青山学院大3年の黒川さくらさん"
                            media="毎日キレイ"
                            datetext="2019.11.16"
                            icon="https://pbs.twimg.com/profile_images/1240514028935335936/W92b7cxv_400x400.jpg"
                        />
                    </div>
                    <div style={{ maxWidth: 250 }}>
                        <MediaBox
                            href="https://mdpr.jp/news/detail/1895945"
                            src="https://img-mdpr.freetls.fastly.net/article/gvkC/wm/gvkC6_1F-OOPvUPTuyCfpvjF6vN9KzzmnHzCIiEsUcM.jpg?width=700&disable=upscale&auto=webp"
                            title="“日本一の大学サークル美女”青山学院・黒川さくらさんに決定"
                            media="modelpress"
                            datetext="2019.11.16"
                            icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
                        />
                    </div>
                    <div style={{ maxWidth: 250 }}>
                        <MediaBox
                            href="http://www.wws-channel.com/fashion2/177485.html"
                            src="http://www.wws-channel.com/wp-content/uploads/2019/11/466466207f95fd904babb6c1bbccc7b5.jpg"
                            title="【動画】大学サークル美女のグランプリは青山学院・黒川さくらさん！「MISS CIRCLE CONTEST 2019」（ミスサークルコンテスト）表彰式が開催"
                            media="WWSチャンネル"
                            datetext="2019.11.17"
                            icon="https://pbs.twimg.com/profile_images/877521071393591296/w15XHGOE_400x400.jpg"
                        />
                    </div>
                    <div style={{ maxWidth: 250 }}>
                        <MediaBox
                            href="https://mdpr.jp/interview/detail/1899769"
                            src="https://img-mdpr.freetls.fastly.net/article/Ua_t/wm/Ua_tIlgKxNxXREtVFMUTdQK-LgG-lUOIiYw1JGaH8YA.jpg?width=700&disable=upscale&auto=webp"
                            title="“日本一の大学サークル美女”準グラ・斎藤渚さんを直撃　映画出演も…女優の道を走る"
                            media="modelpress"
                            datetext="2019.11.23"
                            icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
                        />
                    </div>
                    <div style={{ maxWidth: 250 }}>
                        <MediaBox
                            href="https://mdpr.jp/interview/detail/1899774"
                            src="https://img-mdpr.freetls.fastly.net/article/M22x/wm/M22xIjMAO7Ta4BNERlva0PHpj5TcsdeN3zNyuomXCCU.jpg?width=700&disable=upscale&auto=webp"
                            title="“日本一の大学サークル美女”準グラ・町遥香さんを直撃　フードファイトサークルを立ち上げた真相とは"
                            media="modelpress"
                            datetext="2019.11.23"
                            icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
                        />
                    </div>
                    <div style={{ maxWidth: 250 }}>
                        <MediaBox
                            href="https://mdpr.jp/interview/detail/1899731"
                            src="https://img-mdpr.freetls.fastly.net/article/Oi8Z/wm/Oi8ZvIOuGCwSluJfiYMJ_dvjvthX6iCXR7cDJtD7yEM.jpg?width=700&disable=upscale&auto=webp"
                            title="“日本一の大学サークル美女”黒川さくらさんを直撃　NHKアナウンサー目指す素顔とは"
                            media="modelpress"
                            datetext="2019.11.23"
                            icon="https://pbs.twimg.com/profile_images/1156411788985978880/vJyWfh5H_400x400.png"
                        />
                    </div>
                </MediaBoxes>
            </div>
        </section>
    )
}