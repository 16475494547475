import React, { useMemo } from 'react';
import {Award, Entry, Onlive, Optional} from '@2020-contest-web/components/src/types';
import classNames from 'classnames';
import {
  Photo,
  InstagramButton,
  TwitterButton,
  Footer,
  SectionTitle,
  Entries,
  Header,
  EntryCard,
  ShowroomButton,
  VotedModal, ProfileBreadCrumb, VoteButton, MystaButton,
} from '@2020-contest-web/components/src/components';
import { Helmet } from 'react-navi-helmet-async';
import { Link, useCurrentRoute } from 'react-navi';
import { useResource } from 'retil';
import { entryList, onliveList } from '../../resources';
import {useStageInfo} from "@2020-contest-web/components/src/hooks";

interface ProfileAppProps {
  entry: Entry;
}

export function ProfileApp({ entry }: ProfileAppProps) {
  const {
    url: { query },
  } = useCurrentRoute();
  const [list] = useResource(entryList);
  const [onlives] = useResource(onliveList);
  const { stagePath } = useStageInfo(entry.stage, entry.block);

  const onlive: Optional<Onlive> = useMemo(() => {
    return onlives.data.filter(item => item.entry_id === entry.entry_id)[0];
  }, [onlives.data, entry.entry_id]);

  return (
    <main className="ProfileApp">
      <Helmet>
        <title>
          {entry.public_name ?? ''} | {process.env.REACT_APP_WEBSITE_NAME}
        </title>
        <meta content={`${entry.public_name ?? ''} | ${process.env.REACT_APP_WEBSITE_NAME}`} property="og:title" />
        <meta content={`${entry.image ?? process.env.REACT_APP_URL + '/img/ogp.jpg'}`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/profiles/${entry.entry_id}`} property="og:url" />
      </Helmet>
      <VotedModal {...query} src={entry.image ?? undefined} />
      <Header />
      <section className="section as-profile">
        <div className="container">
          <ProfileBreadCrumb entry={entry} />
          <div className="columns is-variable is-8">
            <div className="column">
              <a
                className="profile-image-link"
                target="_blank"
                href={onlive ? `https://www.showroom-live.com/${onlive.roomUrl}` : undefined}
              >
                <Photo
                  className={classNames('is-solided', {
                    'is-onlive': !!onlive,
                  })}
                  src={entry.image ?? '/img/noimage.svg'}
                  alt={entry.public_name ?? ''}
                >
                  <figcaption className="entry-card-mask">
                    <figure className="image is-1by1">
                      <svg width="240" height="240" viewBox="0 0 240 240">
                        <path fill="none" d="M15,14.5 v210 h210" />
                        <path fill="none" d="M225,225 v-210 h-210" />
                      </svg>
                      <figcaption>
                        <p className="has-text-weight-bold">配信サイトを見る</p>
                      </figcaption>
                    </figure>
                  </figcaption>
                </Photo>
              </a>
            </div>
            <div className="column">
              {hasAward(entry, 'rize_halloween') && (
                  <div className="tags has-addons is-margin-bottom-0-5">
                  <span className="tag has-text-white" style={{ background: '#db6400' }}>
                    <i className="fas fa-trophy" />
                  </span>
                    <span className="tag has-text-white has-text-weight-bold" style={{ background: '#ed8240' }}>
                    リゼハロウィン賞
                  </span>
                  </div>
              )}
              <div className="columns is-size-3 is-variable is-multiline">
                <div className="column is-narrow">
                  <div className="columns">
                    <div className="column is-narrow" style={{ maxWidth: '100%' }}>
                      <h1>{entry.public_name}</h1>
                    </div>
                  </div>
                </div>
                <div className="column is-narrow is-padding-top-0-mobile">
                  <div className="columns is-mobile is-multiline is-variable is-2 is-wrap">
                    {entry.showroom && (
                      <div className="column is-narrow is-flex is-align-items-center">
                        <ShowroomButton
                          href={`https://www.showroom-live.com/room/profile?room_id=${entry.showroom}`}
                          target="_blank"
                        />
                      </div>
                    )}
                    {entry.mysta && (
                        <div className="column is-narrow is-flex is-align-items-center">
                          <MystaButton
                              href={`https://appweb.mysta.tv/web/share/profile?id=${entry.mysta}`}
                              target="_blank"
                          />
                        </div>
                    )}
                    {entry.instagram && (
                      <div className="column is-narrow">
                        <InstagramButton
                          href={`https://instagram.com/${entry.instagram}`}
                          target="_blank"
                          style={{ marginTop: 3 }}
                        />
                      </div>
                    )}
                    {entry.twitter && (
                      <div className="column is-narrow">
                        <TwitterButton
                          href={`https://twitter.com/${entry.twitter}`}
                          target="_blank"
                          style={{ marginTop: 3 }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Detail */}
              <div className="is-margin-bottom-1">
                <p className="has-text-primary">大学・学年</p>
                <p className="is-size-5">
                  {entry.university} {entry.grade && `${entry.grade}年`}
                </p>
              </div>
              {entry.circle && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-primary">所属サークル</p>
                  <p className="is-size-5">{entry.circle}</p>
                </div>
              )}
              {entry['birth-day'] && entry['birth-month'] && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-primary">生年月日</p>
                  <p className="is-size-5">{`${entry['birth-month']}/${entry['birth-day']}`}</p>
                </div>
              )}
              {entry.hometown && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-primary">出身地</p>
                  <p className="is-size-5">{entry.hometown}</p>
                </div>
              )}
              {entry.hobby && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-grey">趣味</p>
                  <p>{entry.hobby}</p>
                </div>
              )}
              {entry.skill && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-grey">特技</p>
                  <p>{entry.skill}</p>
                </div>
              )}
              {entry.dream && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-grey">将来の夢</p>
                  <p>{entry.dream}</p>
                </div>
              )}
              {entry.challenge && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-grey">学生時代に挑戦したいこと</p>
                  <p>{entry.challenge}</p>
                </div>
              )}
              {entry.ambition && (
                <div className="is-margin-bottom-1">
                  <p className="has-text-grey">意気込み</p>
                  <p>{entry.ambition}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      {/*
                Entries
            */}
      <section id="entries" className="section has-background-white-ter">
        <div className="container">
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={'Entries'} subtitle={'出場者紹介'} />
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries state={list} size={4} except={entry} block={entry.block} stage={entry.stage}>
              {(list: Entry[]) =>
                list.map((item: Entry, index: number) => (
                  <div
                    className={classNames('column is-3-desktop is-4-tablet is-6-mobile', {
                      'is-hidden-tablet-only': index === 3,
                    })}
                    key={item.entry_id}
                  >
                    <EntryCard onlive={isOnlive(item)} entry={item} href={`/profiles/${item.entry_id}`} />
                  </div>
                ))
              }
            </Entries>
          </div>
          <div className="has-text-centered">
            <Link className="button is-rounded is-medium is-misscircle" href={stagePath}>
              <span>もっと見る</span>
            </Link>
          </div>
        </div>
      </section>
      <Footer href="mailto:circle@age.co.jp" />
    </main>
  );
  function isOnlive(entry: Entry) {
    const [onlive] = onlives.data.filter((onlive: Onlive) => onlive.entry_id == entry.entry_id);
    return !!onlive;
  }
  function hasAward(entry: Entry, award: Award) {
    const awards = entry.awards ?? [];
    return !!awards.filter(a => a === award)[0];
  }

}
