import React from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import LazyImage from '@2020-contest-web/components/src/components/LazyImage';

export default compose(
  mount({
    '/': route({
      getView: () => InterviewArticleApp,
      title: `2019年準グランプリ 斎藤渚「審査の特典として雑誌の掲載権を得ることができたことがとても嬉しかったです。‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`
    }),
  }),
);

const InterviewArticleApp = ({}) => {
  return (
    <>
      <Helmet>
        <meta
            content={`2019年準グランプリ 斎藤渚「審査の特典として雑誌の掲載権を得ることができたことがとても嬉しかったです。‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`}
            property="og:title"
        />
        <meta content={`${process.env.REACT_APP_URL}/img/interview/saito-nagisa/ogp.jpg`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/interview/saito-nagisa`} property="og:url" />
      </Helmet>
      {/* Image */}
      <section className="section as-image-section" style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
        <div className="container">
          <figure className="image is-256by134">
            <img src="/img/interview/saito-nagisa/hero.jpg" />
          </figure>
        </div>
      </section>
      <div>
        {/* Question 1  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q1.</span>
                    <span>コンテストに参加したきっかけは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">SNSでミスサークルコンテストのことを知りました。</strong>沢山の方に私の存在を知っていただける大きな機会であると思い、コンテストに応募させていただきました。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 2  */}
        <section className="section">
          <div className="container">
            <div className="columns is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                    className="image is-1by1"
                    src="/img/interview/saito-nagisa/2019.jpg"
                    width={640}
                    height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q2.</span>
                    <span>参加して嬉しかったことは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">沢山の方に出会えたこと</strong>です！
                    コンテストが終わってもファンの方々が私のことを応援してくださり、毎日支えられています。
                    また、<strong className="has-text-marker">審査の特典として、雑誌の掲載権を得ることができたこと</strong>がとても嬉しかったです。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 3  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q3.</span>
                    <span>審査期間中に気をつけたことは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">体調やスケジュールの管理と、感謝の気持ちを忘れないこと</strong>です。
                    全てを投げ出したくなるような困難に何度も遭遇しますが、そんな時に支えて下さるファンの皆さんや家族、友人への感謝の気持ちを忘れないようにすると、また頑張ろう！と気持ちを入れ替えることができます。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 4  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q4.</span>
                    <span>準グランプリを受賞して変わったことは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">芸能事務所からオファーがあったり、撮影案件をいただくことがあったりなど、芸能活動の機会が増えました。</strong>
                    受賞したことで、突然人生が180度変わるといったことはありませんが、、<strong className="has-text-marker">コンテストに出場したことで人生の選択肢が増えました。</strong>受賞後、自らがどう動くかが重要だと思います！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Image */}
        <section className="section as-image-section">
          <LazyImage className="image" src="/img/interview/saito-nagisa/divider.jpg" />
        </section>
        {/*  Question 5  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q5.</span>
                    <span>将来の夢は？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">女優として生きることです。</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 6  */}
        <section className="section">
          <div className="container">
            <div className="columns is-row-reverse is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                    className="image is-1by1"
                    src="/img/interview/saito-nagisa/dream.jpg"
                    width={640}
                    height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q6.</span>
                    <span>エントリーする方へのアドバイスは？</span>
                  </div>
                  <div className="faq-answer">
                    ミスサークルコンテストのその先を見据えている方は、コンテスト期間中にプライベートを優先することはあまりお勧めしません(笑)<br />
                    <strong className="has-text-marker">ファイナル審査に進む出場者はそのくらい本気です！</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" style={{ paddingBottom: '6rem' }}>
          <div className="container" style={{ maxWidth: 580 }}>
            <div className="columns">
              <div className="column">
                <div className="box">
                  <article className="media">
                    <div className="media-left">
                      <LazyImage
                          className="image is-64x64"
                          src="/img/interview/saito-nagisa/dream.jpg"
                          width={640}
                          height={640}
                      />
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <small>2019年準グランプリ</small>
                          <br />
                          <strong>斎藤渚 (Saito Nagisa)</strong>
                          <br />
                          <small>'98.05.16 東京都出身</small>
                          <br />
                          <small>中央大学 総合政策学部</small>
                          <br />
                          ドラマや舞台など、数多くのメディアにて活躍中。
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
