import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-navi';
import {LandingSections} from "../../components";

function InterviewApp({children}: any) {
  return (
    <Main className="InterviewApp">
      <Hero className="hero" style={{ overflow: 'hidden' }}>
        <div className="hero-head">
          <div className="navbar">
            <div className="container">
              <div className="navbar-brand">
                <Link className="navbar-item" href="/">
                  <img src={'/img/logo_dark.svg'} alt={process.env.REACT_APP_WEBSITE_NAME} />
                </Link>
              </div>
              <div className="navbar-end">
                <div className="navbar-item buttons">
                  <Link className="button is-text-futura" href="/">
                    <span className="icon">
                      <i className="fas fa-external-link-alt"></i>
                    </span>
                    <span>TOP</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Hero>
      {children}
      <LandingSections />
    </Main>
  );
}

const Main = styled.main``;

const Hero = styled.section`
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-item img {
    height: 50px;
    max-height: none;
  }
`;

export default InterviewApp;
