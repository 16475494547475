import React from 'react';
import history from '../../json/history.json';
import News from '@2020-contest-web/components/src/components/News';
import news from '../../json/news.json';
import {
  ChanceSection,
  HistoryTable,
  InterviewBoxes,
  InterviewBox,
  InterviewSection,
  SectionTitle,
  Twitter,
  Footer,
  ContestFaq,
  Entries,
  EntryCard,
  SisterContestSection,
  ShowroomImage,
  Onlives,
  OnLiveLink,
  ShowroomEventBox, MystaImage, Photo,
} from '@2020-contest-web/components/src/components/';
import { ScheduleSection } from '../ScheduleSection';
import { MediaSection } from '../MediaSection';
import { useResource } from 'retil';
import { entryList, onliveList } from '../../resources';
import { Entry, Onlive } from '@2020-contest-web/components/dist/types';
import classNames from 'classnames';
import { Link } from 'react-navi';

export function LandingSections() {
  const [state] = useResource(entryList);
  const [onlives] = useResource(onliveList);
  return (
    <>
      <section id="award" className="section has-background-gold-stripe" style={{ paddingTop: 150 }}>
        <div className="container" style={{ maxWidth: 740 }}>
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={'Award'} subtitle={'受賞者一覧'} />
          {/* グランプリ */}
          <div className="has-text-centered is-margin-bottom-4">
            <h1 className="is-size-3 has-text-weight-bold is-margin-bottom-2">グランプリ</h1>
            <div className="columns is-centered is-mobile">
              <div className="column is-full" style={{ maxWidth: 400 }}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/917">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/b698e75b2cd2c2ab38b927dcf4b7b8cf3e5b5fce.jpg'}
                        alt={'森明日香'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'森明日香'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'日本女子大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* 準グランプリ */}
          <div className="has-text-centered is-margin-bottom-4">
            <h1 className="is-size-4 is-margin-bottom-2 has-text-weight-bold">準グランプリ</h1>
            <div className="columns is-centered is-mobile">
              <div className="column is-half" style={{maxWidth: 320}}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/35">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/35.jpg'}
                        alt={'薬真寺伽帆'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'薬真寺伽帆'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'愛知淑徳大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 320}}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/144">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/7f892ba5b3be9ee8d05c3886d7b8897393c8bde6.jpg'}
                        alt={'小山倫可'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'小山倫可'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'法政大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>

            </div>
          </div>
          {/* 審査員特別賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <h1 className="is-size-4 is-margin-bottom-2 has-text-weight-bold">審査員特別賞</h1>
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/83">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/a31d98ee85a80cbcd6e86df82709faf12717dbb1.jpg'}
                        alt={'工藤ありさ'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'工藤ありさ'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'宮崎公立大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/243">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/b1e05c3ad52d5b9a2be86a2d0a4926e10c2b5819.jpg'}
                        alt={'石井美帆'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'石井美帆'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'日本大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/275">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/f7cb6915efb6dd646c124589e9b1d9298071dea4.jpg'}
                        alt={'海野名津紀'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'海野名津紀'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'早稲田大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* リゼクリニック賞・リゼウォーク賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  リゼクリニック賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/7">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/7.jpg'}
                        alt={'小松原光里'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'小松原光里'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'就実大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  リゼウォーク賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/275">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/f7cb6915efb6dd646c124589e9b1d9298071dea4.jpg'}
                        alt={'海野名津紀'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'海野名津紀'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'早稲田大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* フジテレビアナトレ賞・modelpress賞・ANNIVERSAIRE賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  フジテレビアナトレ賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/144">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/7f892ba5b3be9ee8d05c3886d7b8897393c8bde6.jpg'}
                        alt={'小山倫可'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'小山倫可'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'法政大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  modelpress賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/7">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/7.jpg'}
                        alt={'小松原光里'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'小松原光里'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'就実大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  ANNIVERSAIRE賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/193">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/572f08a02f0951887a386ac97db42ff36f037b78.jpg'}
                        alt={'上出梨乃'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'上出梨乃'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'早稲田大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* SHOWROOM賞・B.L.T.賞・mysta賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  SHOWROOM賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/243">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/b1e05c3ad52d5b9a2be86a2d0a4926e10c2b5819.jpg'}
                        alt={'石井美帆'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'石井美帆'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'日本大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  B.L.T.賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/7">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/7.jpg'}
                        alt={'小松原光里'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'小松原光里'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'就実大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  mysta賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/83">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/a31d98ee85a80cbcd6e86df82709faf12717dbb1.jpg'}
                        alt={'工藤ありさ'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'工藤ありさ'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'宮崎公立大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* JMSolution賞・Bellcida賞・5LANC賞・VIJECT賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  JMSolution賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/115">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/45fddf0576e66fab1ba7daa638e0211bbc56b5d0.jpg'}
                        alt={'奥野粋子'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'奥野粋子'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'関西大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  Bellcida賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/698">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/698.jpg'}
                        alt={'青野日向子'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'青野日向子'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'長崎大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  5LANC賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/746">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/1bb6ebf7e4db009e4871bf413b592f33a37a1396.jpg'}
                        alt={'新居見葵'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'新居見葵'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'同志社女子大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  VIJECT賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/696">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/696.jpg'}
                        alt={'赤石美友'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'赤石美友'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'八戸工業大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* MISS COLLE賞 */}
          <div className="has-text-centered is-margin-bottom-4">
            <div className="columns is-mobile is-centered is-multiline">
              <div className="column is-half" style={{maxWidth: 240}}>
                <h1 className="is-size-5 is-size-6-mobile is-margin-bottom-2 has-text-weight-bold">
                  MISS COLLE賞
                </h1>
                <div className={classNames('entry-card')}>
                  <Link href="/profiles/115">
                    <Photo
                        className={classNames('is-solided')}
                        src={'https://hauptmann.age.co.jp/contests/45fddf0576e66fab1ba7daa638e0211bbc56b5d0.jpg'}
                        alt={'奥野粋子'}
                    >
                      <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                          <svg width="240" height="240" viewBox="0 0 240 240">
                            <path fill="none" d="M15,14.5 v210 h210" />
                            <path fill="none" d="M225,225 v-210 h-210" />
                          </svg>
                          <figcaption>
                            <p className="has-text-weight-bold">詳しく見る</p>
                          </figcaption>
                        </figure>
                      </figcaption>
                    </Photo>
                    <div className="entry-card-title">
                      <p className="entry-card-title-name">{'奥野粋子'}</p>
                      <p className="entry-card-title-university has-text-black-bis">{'関西大学'}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ paddingTop: 150 }}>
        <div className="container" style={{ maxWidth: 740 }}>
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={'Event'} subtitle={'表彰式'} />
          <table className="table is-fullwidth as-information">
            <tbody>
            <tr>
              <th className="is-narrow">日時</th>
              <td>
                2020年12月06日(日) 開演 14:30
                <br/>
                <span className="is-size-7">※ 無観客にて開催いたします。</span>
              </td>
            </tr>
            <tr>
              <th className="is-narrow">会場</th>
              <td>
                <a className="has-text-grey-dark" href="https://www.ex-theater.com/" target="_blank">
                  EX THEATER ROPPONGI
                </a>
              </td>
            </tr>
            <tr>
              <th className="is-narrow">ライブ配信</th>
              <td>
                SHOWROOM、17LIVEにて無料配信
              </td>
            </tr>
            <tr>
              <th className="is-narrow">視聴URL</th>
              <td>
                <a href="https://www.showroom-live.com/circle2020" target="_blank">SHOWROOM</a><br/>
                <a href="https://17.live/profile/r/13527249" target="_blank">17LIVE</a>
              </td>
            </tr>
            <tr>
              <th className="is-narrow">MC</th>
              <td>
                <span className="has-text-marker">トレンディエンジェル</span>
                <br/>
                <br/>
                <img src="/img/mc.jpg" />
              </td>
            </tr>
            <tr>
              <th className="is-narrow">GUEST</th>
              <td>
                <span className="has-text-marker">山賀琴子</span>
                <br/>
                <br/>
                <img src="/img/guest.jpg" width="320"/>
              </td>
            </tr>
            <tr>
              <th className="is-narrow">PRESENTER</th>
              <td>
                <span className="has-text-marker">黒川さくら</span>
                <br /><span className="is-size-7-mobile is-size-6 is-margin-top-0-5">青山学院大学 4年</span>
                <br /><span className="is-size-7-mobile is-size-6 is-margin-top-0-5">MISS CIRCLE CONTEST 2019 <br className="is-hidden-tablet" />グランプリ</span>
                <br/>
                <br/>
                <img src="https://2019.misscircle.jp/images/entries/8.jpg" width="320"/>
                <br/>
                <hr />
                <span className="has-text-marker">町遥香</span>
                <br /><span className="is-size-7-mobile is-size-6 is-margin-top-0-5">学習院大学 4年</span>
                <br /><span className="is-size-7-mobile is-size-6 is-margin-top-0-5">MISS CIRCLE CONTEST 2019 <br className="is-hidden-tablet" />準グランプリ</span>
                <br/>
                <br/>
                <img src="https://2019.misscircle.jp/images/entries/87.jpg" width="320"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={'Prize'} subtitle={'グランプリ特典'} />
          <div className="columns is-variable is-10 is-centered is-padding-bottom-2">
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile">
              <figure className="image" style={{ maxWidth: 480, borderRadius: 10, overflow: 'hidden' }}>
                <img src="/img/prize.jpg" />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <SectionTitle
            className="title"
            style={{ marginBottom: 60 }}
            title={'SPECIAL SPONSOR'}
            subtitle={'特別協賛'}
          />
          <div className="columns is-variable is-10 is-centered is-padding-bottom-2">
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile">
              <figure className="image has-text-centered">
                <img src="/img/partner/rize.svg" style={{ display: 'inline-block', maxHeight: 100, width: 'auto' }} />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <SectionTitle
            className="title"
            style={{ marginBottom: 60 }}
            title={'SPECIAL PARTNER'}
            subtitle={'特別協力'}
          />
          <div className="columns is-variable is-10 is-centered is-padding-bottom-2" style={{ alignItems: 'center' }}>
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile" style={{ width: 250 }}>
              <figure className="image has-text-centered">
                <img src="/img/partner/anatore.png" style={{ display: 'inline-block', maxHeight: 45, width: 'auto' }} />
              </figure>
            </div>
            <div className="column is-narrow is-padding-bottom-2-mobile">
              <figure className="image has-text-centered">
                <img src="/img/partner/jj.jpg" style={{ display: 'inline-block', maxHeight: 40, width: 'auto' }} />
              </figure>
            </div>
            <div className="column is-narrow is-margin-0-auto-mobile is-padding-bottom-2-mobile" style={{ width: 285 }}>
              <figure className="image has-text-centered">
                <img
                  src="/img/partner/modelpress.png"
                  style={{ display: 'inline-block', maxHeight: 37, width: 'auto' }}
                />
              </figure>
            </div>
            <div className="column is-narrow is-margin-0-auto-mobile">
              <figure className="image has-text-centered">
                <img
                    src="/img/partner/ray.svg"
                    style={{ display: 'inline-block', maxHeight: 48, width: 'auto' }}
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section id="partner" className="section">
        <div className="container">
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={'PARTNER'} subtitle={'協賛'} />
          <div className="columns is-variable is-10 is-centered is-padding-bottom-2">
            <div className="column is-narrow is-margin-0-auto-mobile">
              <figure className="image has-text-centered is-padding-bottom-2-mobile">
                <img
                    src="/img/partner/anniversaire.svg"
                    style={{ display: 'inline-block', maxHeight: 48, width: 'auto' }}
                />
              </figure>
            </div>
            <div className="column is-narrow is-padding-bottom-2-mobile is-margin-0-auto-mobile" style={{ width: 285 }}>
              <figure className="image has-text-centered">
                <img src="/img/partner/mysta.png" style={{ display: 'inline-block', maxHeight: 30, width: 'auto' }} />
              </figure>
            </div>
          </div>
        </div>
      </section>

      {/* MYSTA
      <section id="mysta" className="section is-mysta is-paddingless-left-mobile is-paddingless-right-mobile">
        <div className="container" style={{ maxWidth: 920 }}>
          <SectionTitle
              className="title"
              title={<MystaImage />}
              style={{ marginBottom: 30 }}
              subtitle={'動画配信'}
          />
        </div>
      </section>
       */}
      {/* SHOWROOM */}
      <section id="showroom" className="section is-showroom is-paddingless-left-mobile is-paddingless-right-mobile">
        <div className="container" style={{ maxWidth: 920 }}>
          <SectionTitle
            className="title"
            title={<ShowroomImage />}
            style={{ marginBottom: 30 }}
            subtitle={'ライブ配信'}
          />
          <h2 className="title is-size-5" style={{ marginLeft: 18, color: 'rgb(238, 238, 238)' }}>
            配信中
          </h2>
          <div className="is-margin-bottom-2">
            <Onlives state={onlives}>
              {(list: Onlive[]) =>
                list.map((item: Onlive, index: number) => (
                  <div key={index} style={{ maxWidth: 200 }}>
                    <OnLiveLink item={item} />
                  </div>
                ))
              }
            </Onlives>
          </div>
        </div>
      </section>
      {/* ENTRY */}
      <section className="section">
        <div className="container">
          <SectionTitle className="title" style={{ marginBottom: 60 }} title={'Entries'} subtitle={'ファイナル審査進出者'} />
          <div className="columns is-multiline is-mobile is-margin-bottom-3">
            <Entries state={state} size={4} stage={'6'}>
              {(list: Entry[]) =>
                list.map((item: Entry, index: number) => (
                  <div
                    className={classNames('column is-3-desktop is-4-tablet is-6-mobile', {
                      'is-hidden-tablet-only': index === 3,
                    })}
                    key={item.entry_id}
                  >
                    <EntryCard onlive={isOnlive(item)} entry={item} href={`/profiles/${item.entry_id}`} />
                  </div>
                ))
              }
            </Entries>
          </div>
          <div className="has-text-centered">
            <Link className="button is-rounded is-medium is-misscircle" href="/entries/final">
              <span>もっと見る</span>
            </Link>
          </div>
        </div>
      </section>
      <ChanceSection />
      <MediaSection />
      <InterviewSection
        color="#f5f5f5"
        profileNode={
          <>
            <div style={{ marginBottom: 10 }}>
              <p>2019年グランプリ</p>
              <p>
                <span className="has-text-weight-bold is-size-5">黒川さくら</span> (Kurokawa Sakura)
              </p>
            </div>
            <p>
              ’98.12.14 神奈川県出身
              <br />
              青山学院大学 教育人間学部
              <br />
              同大アナウンス研究会に所属しアナウンサーへの道を志す。
            </p>
          </>
        }
        titleNode={
          <h2 className="title">
            自分の可能性を広げる
            <br className="is-hidden-tablet" /> はじめの1歩 を、
            <br />
            ぜひ踏み出して欲しいです。‬‬
          </h2>
        }
        textNode={
          <>
            <p>
              出場前の私は自分に自信がなく、
              <br />
              将来の夢が「アナウンサー」だと <br />
              周りにはっきり言えませんでした。
            </p>
            <p>
              そんな自分が嫌でしたが、 <br />
              ミスコンを最後までやりきった自分に <br />
              今では誇りを持っています。‬
            </p>
            <p>
              ‪「大学生の今しかできないことを全力で頑張りたい！」
              <br />
              「夢に少しでも近づきたい！」
            </p>
            <p>
              そう思っているあなたに、 <br />
              ミスサークルコンテストで
              <br />
              自分の可能性を広げるはじめの1歩を、 <br />
              ぜひ踏み出して欲しいです。
            </p>
          </>
        }
      />

      <section className="section has-background-white" style={{ padding: '3rem 0 4rem', overflow: 'hidden' }}>
        <div className="container" style={{ maxWidth: 822 }}>
          <SectionTitle
            className="title"
            style={{ marginBottom: 60 }}
            title={'INTERVIEW'}
            subtitle={'2019受賞者のインタビュー'}
          />
          <InterviewBoxes>
            <div style={{ maxWidth: 250 }}>
              <InterviewBox
                name="町 遥香"
                award="2019準グランプリ"
                href="/interview/haruka-machi"
                src="/img/interview/machi-haruka/thumbnail.jpg"
                message="書店で好きな雑誌を開いたら、自分が掲載されていて驚きました。"
              />
            </div>
            <div style={{ maxWidth: 250 }}>
              <InterviewBox
                name="斎藤 渚"
                award="2019準グランプリ"
                href="/interview/nagisa-saito"
                src="/img/interview/saito-nagisa/thumbnail.jpg"
                message="審査の特典として雑誌の掲載権を得ることができたことがとても嬉しかったです。"
              />
            </div>
            <div style={{ maxWidth: 250 }}>
              <InterviewBox
                name="黒川 さくら"
                award="2019グランプリ"
                href="/interview/sakura-kurokawa"
                src="/img/interview/kurokawa-sakura/thumbnail.jpg"
                message="自分に自信が身につきました。今では堂々と『私の夢はアナウンサーです』と言うことができます。"
              />
            </div>
          </InterviewBoxes>
        </div>
      </section>
      <section className="section has-background-white">
        <div className="container" style={{ maxWidth: 750 }}>
          <SectionTitle className="title" style={{ marginBottom: 30 }} title={'REQUIREMENT'} subtitle={<>応募資格</>} />

          <div className="message">
            <div className="message-body content" style={{ paddingTop: '0.5rem' }}>
              <ul>
                <li>
                  応募受付対象者：四年制大学・短期大学・大学院・専門学校等、それらに相当する学校に在籍する女性の方。
                </li>
                <li>
                  学生中心で形成されたサークル、部活、同好会、ゼミ、研究会、及びそのいずれかに準ずるコミュニティに所属していること。
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <ScheduleSection />
      <section className="section has-background-white-ter">
        <div className="container" style={{ maxWidth: 750 }}>
          <SectionTitle className="title" style={{ marginBottom: 30 }} title={'FAQ'} subtitle={<>よくある質問</>} />
          <div>
            <ContestFaq
              question="エントリー時に必要な写真はどのような写真が良いですか？"
              answer={
                <>
                  半年以内に撮影した顔がはっきりと分かるお写真をご用意ください。
                  <br />
                  かしこまった写真のご提出ではなく、現在お持ちのものでも結構ですし、自撮りの写真でも問題ありません。複数人が写っている写真の場合は、ご自身のみが写るよう切り取ったものをお送りください。（※プリクラは不可）
                </>
              }
            />
            <ContestFaq
              question="どのような審査がありますか？"
              answer={
                <>
                  全国を対象としたコンテストであるため、審査はインターネット上で行なわれます。例年、WEB投票や各種SNSのを活用した審査がございます。
                </>
              }
            />
            <ContestFaq
              question="バイトしながらでも審査に影響はないですか？"
              answer={
                <>
                  問題ございません。実際、過去のグランプリや受賞者の多くはアルバイトや他の課外活動を行いながら審査に参加されていました。
                </>
              }
            />
            <ContestFaq
              question="大学や学校によって審査に有利、不利などはありますか？"
              answer={<>在籍されている学校による有利不利は一切ございません。</>}
            />
            <ContestFaq
              question="新型コロナウイルス感染症の影響で中止になることはありますか？"
              answer={
                <>
                  現時点で中止することは想定しておりません。ただし、今後の状況や情勢や関係機関の方針により、スケジュールや内容を変更させていただく可能性もございます。
                </>
              }
            />
            <ContestFaq
              question="芸能事務所に所属していても応募は可能ですか？"
              answer={<>はい可能です。ただし、所属事務所に必ず許可を得た上で応募ください。</>}
            />
          </div>
        </div>
      </section>
      <section className="section has-background-white">
        <div className="container">
          <SectionTitle
            className="title"
            style={{ marginBottom: 30 }}
            title={'HISTORY'}
            subtitle={<>歴代受賞者一覧</>}
          />
          <HistoryTable json={history} />
        </div>
      </section>
      <SisterContestSection themeColor="#a96372" src="/img/frecam.svg">
        <p className="has-text-weight-bold" style={{ marginBottom: 30 }}>
          日本一の新入生を決めるミスコンテスト！
        </p>
        <a href="https://2020.frecam.jp/" className="button is-medium is-rounded is-frecam">
          特設サイトを見る
        </a>
      </SisterContestSection>

      <section id="news" className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <SectionTitle className="title" style={{ marginBottom: 30 }} title={'News'} subtitle={<>お知らせ</>} />
              <News json={news} />
            </div>
            <div className="column">
              <SectionTitle className="title" style={{ marginBottom: 30 }} title={'FOLLOW'} subtitle={<>最新情報</>} />
              <div className="is-margin-bottom-3">
                <Twitter />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer href="mailto:circle@age.co.jp" />
    </>
  );
  function isOnlive(entry: Entry) {
    const [onlive] = onlives.data.filter((onlive: Onlive) => onlive.entry_id == entry.entry_id);
    return !!onlive;
  }
}
