import React from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import LazyImage from '@2020-contest-web/components/src/components/LazyImage';

export default compose(
  mount({
    '/': route({
      getView: () => InterviewArticleApp,
    }),
  }),
);

const InterviewArticleApp = ({}) => {
  return (
    <>
      <Helmet>
        <meta
            content={`2019年準グランプリ 町遥香「書店で好きな雑誌を開いたら、自分が掲載されていて驚きました。‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`}
            property="og:title"
        />
        <meta content={`${process.env.REACT_APP_URL}/img/interview/machi-haruka/ogp.jpg`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/interview/haruka-machi`} property="og:url" />
      </Helmet>
      {/* Image */}
      <section className="section as-image-section" style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
        <div className="container">
          <figure className="image is-256by134">
            <img src="/img/interview/machi-haruka/hero.jpg" />
          </figure>
        </div>
      </section>
      <div>
        {/* Question 1  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q1.</span>
                    <span>コンテストに参加したきっかけは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">やったことのないことをやってみたかった</strong>
                    と思ったので参加しました。ミスサークルコンテストは前年に友達が参加していたため知っていました。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 2  */}
        <section className="section">
          <div className="container">
            <div className="columns is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                    className="image is-1by1"
                    src="/img/interview/machi-haruka/2019.jpg"
                    width={640}
                    height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q2.</span>
                    <span>参加して嬉しかったことは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">好きな雑誌に1ページ特集を載せてもらえたこと</strong>
                    です。掲載を知る前に、書店で偶然雑誌を開いたら自分が掲載されていて驚きました。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 3  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q3.</span>
                    <span>審査期間中に気をつけたことは？</span>
                  </div>
                  <div className="faq-answer">
                    コンテスト後半に風邪をひくと大きな痛手になるため、
                    <strong className="has-text-marker">体調管理を気をつけました。</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 4  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q4.</span>
                    <span>準グランプリを受賞して変わったことは？</span>
                  </div>
                  <div className="faq-answer">
                    受賞して大きく変わったことはありません。肩書きを活かすのも自分自身なので、
                    <strong className="has-text-marker">この経験が無駄にならないよう今後活躍していきたい</strong>です。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Image */}
        <section className="section as-image-section">
          <LazyImage className="image" src="/img/interview/machi-haruka/divider.jpg" />
        </section>
        {/*  Question 5  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q5.</span>
                    <span>将来の夢は？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">影響力のある仕事に就きたい</strong>
                    と思っています。具体的なお仕事は考え中です。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 6  */}
        <section className="section">
          <div className="container">
            <div className="columns is-row-reverse is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                    className="image is-1by1"
                    src="/img/interview/machi-haruka/dream.jpg"
                    width={640}
                    height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q6.</span>
                    <span>エントリーする方へのアドバイスは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">
                      自分のスタンスは変えないで活動してもらえたらと思います。
                    </strong>
                    たくさんの人から様々な意見をもらいますが、自分に合った意見を参考にして取り組んでください。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" style={{ paddingBottom: '6rem' }}>
          <div className="container" style={{ maxWidth: 580 }}>
            <div className="columns">
              <div className="column">
                <div className="box">
                  <article className="media">
                    <div className="media-left">
                      <LazyImage
                          className="image is-64x64"
                          src="/img/interview/machi-haruka/dream.jpg"
                          width={640}
                          height={640}
                      />
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <small>2019年準グランプリ</small>
                          <br />
                          <strong>町遥香 (Machi Haruka)</strong>
                          <br />
                          <small>'98.06.01 埼玉県出身</small>
                          <br />
                          <small>学習院大学 文学部</small>
                          <br />
                          ミスコンで磨いた発信力を活かし、同世代向けのインフルエンサーとして活躍を目指す。
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
