import React from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import LazyImage from '@2020-contest-web/components/src/components/LazyImage';

export default compose(
  mount({
    '/': route({
      getView: () => InterviewArticleApp,
      title: `2019年グランプリ 黒川さくら「自分に自信が身につきました。今では堂々と『私の夢はアナウンサーです』と言うことができます。‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`
    }),
  }),
);

const InterviewArticleApp = ({}) => {
  return (
    <>
      <Helmet>
        <meta
            content={`2019年グランプリ 黒川さくら「自分に自信が身につきました。今では堂々と『私の夢はアナウンサーです』と言うことができます。‬」 | ${process.env.REACT_APP_WEBSITE_NAME}`}
            property="og:title"
        />
        <meta content={`${process.env.REACT_APP_URL}/img/interview/kurokawa-sakura/ogp.jpg`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/interview/kurokawa-sakura`} property="og:url" />
      </Helmet>
      {/* Image */}
      <section className="section as-image-section" style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
        <div className="container">
          <figure className="image is-256by134">
            <img src="/img/interview/kurokawa-sakura/hero.jpg" />
          </figure>
        </div>
      </section>
      <div>
        {/* Question 1  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q1.</span>
                    <span>コンテストに参加したきっかけは？</span>
                  </div>
                  <div className="faq-answer">
                    SNS上でミスサークルが開催される告知を見たことがきっかけです。<strong className="has-text-marker">アナウンサーという夢に一歩でも近づきたいという思いがありました。</strong>「とりあえずやってみよう！」って、飛び込む感じです。笑
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 2  */}
        <section className="section">
          <div className="container">
            <div className="columns is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                    className="image is-1by1"
                    src="/img/interview/kurokawa-sakura/2019.jpg"
                    width={640}
                    height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q2.</span>
                    <span>参加して嬉しかったことは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">今までの自分ではできない活動ができたことです。</strong>
                    例えば、テレ朝夏祭りでは、たくさんの人が見ている中、初めて舞台に上がりウォーキングを披露しました。
                    嬉しさと緊張で、ウォーキング中のことはほとんど何も覚えていませんが、応援してくれる方が来てくれた事だけは覚えています笑。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 3  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q3.</span>
                    <span>審査期間中に気をつけたことは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">身だしなみや言葉遣いをしっかりすることです。</strong>お洋服やヘアアレンジ、メイクはもちろんのこと、言葉遣いを今まで以上に気を使いました。「たくさんの人に見られている」という意識を持って過ごしていました。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Question 4  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q4.</span>
                    <span>グランプリを受賞して変わったことは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">自分に自信が身につきました。</strong>
                    「こんな自分でも応援してくれる方がいるんだ」と感じることができました。
                    以前は自信がなく、自分の夢がアナウンサーであることを、周りにあまり言えない弱い自分がいましたが、<strong className="has-text-marker">今では堂々と「私の夢はアナウンサーです」と言うことができます。</strong>アナウンサーの夢を応援してくれる友達も増えました。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Image */}
        <section className="section as-image-section">
          <LazyImage className="image" src="/img/interview/kurokawa-sakura/divider.jpg" />
        </section>
        {/*  Question 5  */}
        <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column" style={{ maxWidth: 640 }}>
                <div className="faq">
                  <div className="faq-question">
                    <span>Q5.</span>
                    <span>将来の夢は？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">アナウンサーです。</strong>
                    いろんな現場へ足を運び、自分の目で見たもの、感じたものを自分の言葉でお伝えしたいです。
                    私の伝えた言葉で、誰かの行動や考えるきっかけに繋がってくれたら嬉しいと思います。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*  Question 6  */}
        <section className="section">
          <div className="container">
            <div className="columns is-row-reverse is-variable is-4 is-centered is-vcentered">
              <div className="column is-6-tablet is-12-mobile">
                <LazyImage
                    className="image is-1by1"
                    src="/img/interview/kurokawa-sakura/dream.jpg"
                    width={640}
                    height={640}
                />
              </div>
              <div className="column is-6-tablet is-12-mobile">
                <div className="faq">
                  <div className="faq-question">
                    <span>Q6.</span>
                    <span>エントリーする方へのアドバイスは？</span>
                  </div>
                  <div className="faq-answer">
                    <strong className="has-text-marker">向き不向きよりも、前向きなことが大事だと思います！</strong><br />
                    最後まで頑張ったミスサークルでの活動は、必ず自分の糧になると心から感じています。
                    頑張った分だけ、応援してくれる方はちゃんと見てくれていますよ！！
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" style={{ paddingBottom: '6rem' }}>
          <div className="container" style={{ maxWidth: 580 }}>
            <div className="columns">
              <div className="column">
                <div className="box">
                  <article className="media">
                    <div className="media-left">
                      <LazyImage
                          className="image is-64x64"
                          src="/img/interview/kurokawa-sakura/dream.jpg"
                          width={640}
                          height={640}
                      />
                    </div>
                    <div className="media-content">
                      <div className="content">
                        <p>
                          <small>2019年グランプリ</small>
                          <br />
                          <strong>黒川さくら (Kurokawa Sakura)</strong>
                          <br />
                          <small>'98.12.14 神奈川県出身</small>
                          <br />
                          <small>青山学院大学 教育人間学部</small>
                          <br />
                          同大アナウンス研究会に所属しアナウンサーへの道を志す。
                        </p>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
