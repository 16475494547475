import React from 'react';
import { mount, compose, withView } from 'navi';
import { View } from 'react-navi';
import InterviewApp from './InterviewApp';
import NagisaSaito from './nagisa-saito';
import HarukaMachi from './haruka-machi';
import SakuraKurokawa from './sakura-kurokawa';
import Bag from './bag';

export default compose(
  withView(() => (
    <InterviewApp>
      <View />
    </InterviewApp>
  )),
  mount({
    '/nagisa-saito': NagisaSaito,
    '/haruka-machi': HarukaMachi,
    '/sakura-kurokawa': SakuraKurokawa,
    '/bag': Bag,
  }),
);
