import React from 'react';
import { View } from 'react-navi';
import { lazy, mount, compose, withView } from 'navi';
import ReactGA from 'react-ga';
import TopApp from './top';
import InterviewApp from './interview';
import EntriesApp from './entries';
import ProfileApp from './profiles';
import PostsApp from './posts';

const apps = compose(
  withView((request: any) => {
    if (process.env.REACT_APP_CONTEXT === 'production') {
      ReactGA.pageview(request.path);
    }
    return <>
        <div className="is-misscircle cpck-window cpck-banner cpck-type-info cpck-theme-block cpck-bottom">
            <span id="cupcakeconsent:desc" className="cpck-message has-text-weight-bold">2024年サイトがオープン！<br />今年の最新情報を配信中!</span>
            <div className="cpck-compliance">
                <a aria-label="dismiss cookie message"
                   href="https://www.misscircle.jp/?utm_source=misscircle2020&utm_medium=operation&utm_campaign=20240401&utm_content=dialog"
                   className="cpck-btn cpck-dismiss"><span>💎</span>2024年公式サイトを見る</a>
            </div>
        </div>
        <View />
    </>;
  }),
  mount({
    '/': TopApp,
    '/entries': EntriesApp,
    '/profiles': ProfileApp,
    '/interview': InterviewApp,
    '/privacy': lazy(() => import('./privacy')),
    '/entryterms': lazy(() => import('./entryterms')),
    '/posts': PostsApp,
  }),
);

export default apps;
