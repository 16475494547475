import {createDocumentResource, createQueryResource, getResourceService, list, receiveData} from 'retil';
import axios from 'axios';
import { toSafeInteger } from '@2020-contest-web/components/src/utils';
import { BaseURL, ContestToken, isMock } from './config';
import {Onlive} from "@2020-contest-web/components/src/types";

export const onlive = createDocumentResource<Onlive>('onlive');
export const onliveList = createQueryResource('onliveList', {
    for: list(onlive),
    load: async () => {
        if (isMock()) {
            return [];
        }
        try {
            const res = await axios.get(BaseURL + `/onlive.json`, {
                headers: {
                    'content-type': 'application/json',
                    'x-access-token': ContestToken,
                },
            });
            return res.data.result.response
                .map((onlive: any) => {
                    const [, numberText] = onlive.roomUrl.split('_');
                    const entry_id = toSafeInteger(numberText);
                    if (entry_id < 1) {
                        return null;
                    }
                    return { ...onlive, entry_id, id: entry_id };
                })
                .filter((onlive: any) => !!onlive);
        } catch {
            return [];
        }
    },
});

// NOTE:- Create Initial State

const [, controller] = getResourceService(onliveList);

// InitialState
receiveData(onliveList, {
    data: [],
});

controller.invalidate();
