import { createDocumentResource, createQueryResource, list } from 'retil';
import axios from 'axios';
import { Entry } from '@2020-contest-web/components/src/types';
import { BaseURL, ContestToken, isMock } from './config';

export const entry = createDocumentResource<Entry>('entry');
export const entryList = createQueryResource('entryList', {
  for: list(entry),
  load: async () => {
    if (isMock()) {
      return await mock();
    } else {
      return await fetch();
    }
  },
});

function validate(source: Entry[]): Entry[] {
  return source
    .filter((entry: Entry) => !!entry.public_name)
    .filter((entry: Entry) => entry.block === 'A' || entry.block === 'B' || entry.block === 'C') // NOTE:- Block A OR B OR C Only
    .map((entry: Entry) => {
      return { ...entry, id: entry.entry_id };
    });
}

async function mock(): Promise<Entry[]> {
  const { result } = await import('../cache/profiles/1.json');
  return validate(result as any);
}

async function fetch(): Promise<Entry[]> {
  try {
    const res = await axios.get(BaseURL + `/profiles/1.json`, {
      headers: {
        'content-type': 'application/json',
        'x-access-token': ContestToken,
      },
    });
    return validate(res.data.result);
  } catch (error) {
    return [];
  }
}
