import React from 'react';
import { route, mount, compose } from 'navi';
import { Helmet } from 'react-navi-helmet-async';
import {Link} from "react-navi";
import { ArticleHeroSection } from '@2020-contest-web/components/src/components/';

export default compose(
  mount({
    '/sakura-kurokawa': route({
      getView: () => InterviewBagOfKurokawaApp,
      title: `【2019年ミスサークル】グランプリ黒川さくらさんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`,
    }),
    '/nagisa-saito': route({
      getView: () => InterviewBagOfSaitoApp,
      title: `【2019年ミスサークル】準グランプリ斎藤渚さんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`,
    }),
    '/haruka-machi': route({
      getView: () => InterviewBagOfMachiApp,
      title: `【2019年ミスサークル】準グランプリ町遥香さんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`,
    }),
  }),
);

const InterviewBagOfKurokawaApp = ({}) => {
  return (
    <>
      <Helmet>
        <meta
          content={`【2019年ミスサークル】グランプリ黒川さくらさんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`}
          property="og:title"
        />
        <meta content={`${process.env.REACT_APP_URL}/img/interview/bag/kurokawa-hero.jpg`} property="og:image" />
        <meta content={`${process.env.REACT_APP_URL}/interview/bag/sakura-kurokawa`} property="og:url" />
      </Helmet>
      <ArticleHeroSection src="/img/interview/bag/kurokawa-hero.jpg" />

      <section className="section" style={{ paddingTop: '2rem', paddingBottom: 0 }}>
        <div className="container" style={{ maxWidth: 640 }}>
          <div className="tags" style={{ marginBottom: 0 }}>
            <p className="tag is-primary has-text-weight-bold">Special Contents</p>
          </div>
          <h1 className="title" style={{ lineHeight: 1.5 }}>
            【2019年ミスサークル】
            <br />
            グランプリ黒川さくらさんに聞いてみた！バッグの中身も大公開👜 👀
          </h1>
          <div className="notification">
            2019年ミスサークルの黒川さくらさんに突撃インタビュー🎤
            <br />
            現在ハマっていることや、大学生活の目標について伺いました。
            さらにバッグの中からお気に入りのアイテムも大公開！👜 ✨
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container" style={{ maxWidth: 640 }}>
          <h1 className="title as-section-title has-text-left has-text-weight-bold">
            現在ハマっていることはありますか？
          </h1>
          <h2 className="subtitle"></h2>
          <div className="media" style={{ marginBottom: '3rem' }}>
            <div className="media-left">
              <figure className="image is-48x48">
                <img
                    className="is-rounded"
                    src="/img/interview/thumb/kurokawa.jpg"
                />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification">
                <strong className="has-text-marker">スチーマーを顔に当てながら動画をみること</strong>にハマっています。温かくて細かい霧がふぁ〜って顔に当たると、肌がもちもちになりますし、何より癒やされます…。気分はエステです。<br />
                動画は、<strong className="has-text-marker">マツコ・デラックスさんが大好きなので「マツコの知らない世界」や「マツコ会議」など</strong>を見ています。
                前までは、Amazonプライムで「鬼滅の刃」のアニメを鑑賞するのにハマっていました。
              </div>
            </div>
          </div>

          <h1 className="title as-section-title has-text-left has-text-weight-bold">
            大学生の間にやりたいこと、目標はありますか？
          </h1>
          <h2 className="subtitle"></h2>
          <div className="media" style={{ marginBottom: '3rem' }}>
            <div className="media-left">
              <figure className="image is-48x48">
                <img
                    className="is-rounded"
                    src="/img/interview/thumb/kurokawa.jpg"
                />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification">
                <strong className="has-text-marker">スノーボード</strong>はやったことがないので挑戦したいです！あと<strong className="has-text-marker">海外旅行</strong>に行って、現地でお友達を作ってみたいですね。
              </div>
            </div>
          </div>

          <h1 className="title as-section-title has-text-left has-text-weight-bold">バッグの中からお気に入りのアイテムを教えて下さい。</h1>
          <h2 className="subtitle"></h2>          <figure className="image">
            <img src="/img/interview/bag/kurokawa.jpg" />
          </figure>
          <p className="subtitle" style={{ marginTop: '1rem' }}>
            「rom&nd」のティント
          </p>
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img
                  className="is-rounded"
                  src="/img/interview/thumb/kurokawa.jpg"
                />
              </figure>
            </div>
            <div className="media-content">
              <div className="notification">
                <strong className="has-text-marker">スケジュール管理は手書き手帳派。</strong>通学中に読む本、モバイルバッテリーはカバンにいつも入れてます。
              </div>
            </div>
          </div>
          <div className="columns" style={{marginTop: "1rem"}}>
            <div className="column">
              <div className="box">
                <article className="media">
                  <div className="media-content">
                    <div className="content" style={{overflow: "hidden"}}>
                      <p>
                        <small>2019年グランプリ</small>
                        <br />
                        <strong>黒川さくら (Kurokawa Sakura)</strong>
                        <br />
                        <small>'98.12.14 神奈川県出身</small>
                        <br />
                        <small>青山学院大学 教育人間学部</small>
                        <br />
                        同大アナウンス研究会に所属しアナウンサーへの道を志す。
                      </p>
                      <div className="buttons is-right">
                        <Link className="button is-small" href="/interview/sakura-kurokawa">特別インタビュー記事を見る</Link>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const InterviewBagOfSaitoApp = ({}) => {
  return (
      <>
        <Helmet>
          <meta
              content={`【2019年ミスサークル】準グランプリ斎藤渚さんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`}
              property="og:title"
          />
          <meta content={`${process.env.REACT_APP_URL}/img/interview/bag/saito-hero.jpg`} property="og:image" />
          <meta content={`${process.env.REACT_APP_URL}/interview/bag/nagisa-saito`} property="og:url" />
        </Helmet>
        <ArticleHeroSection src="/img/interview/bag/saito-hero.jpg" />

        <section className="section" style={{ paddingTop: '2rem', paddingBottom: 0 }}>
          <div className="container" style={{ maxWidth: 640 }}>
            <div className="tags" style={{ marginBottom: 0 }}>
              <p className="tag is-primary has-text-weight-bold">Special Contents</p>
            </div>
            <h1 className="title" style={{ lineHeight: 1.5 }}>
              【2019年ミスサークル】
              <br />
              準グランプリ斎藤渚さんに聞いてみた！バッグの中身も大公開👜 👀
            </h1>
            <div className="notification">
              2019年ミスサークルの斎藤渚さんに突撃インタビュー🎤
              <br />
              現在ハマっていることや、大学生活の目標について伺いました。
              さらにバッグの中からお気に入りのアイテムも大公開！👜 ✨
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container" style={{ maxWidth: 640 }}>
            <h1 className="title as-section-title has-text-left has-text-weight-bold">
              現在ハマっていることはありますか？
            </h1>
            <h2 className="subtitle"></h2>
            <div className="media" style={{ marginBottom: '3rem' }}>
              <div className="media-left">
                <figure className="image is-48x48">
                  <img
                      className="is-rounded"
                      src="/img/interview/thumb/saito.jpg"
                  />
                </figure>
              </div>
              <div className="media-content">
                <div className="notification">
                  Instagramで<strong className="has-text-marker">インコの動画</strong>を見て癒されることです！
                </div>
              </div>
            </div>

            <h1 className="title as-section-title has-text-left has-text-weight-bold">
              大学生の間にやりたいこと、目標はありますか？
            </h1>
            <h2 className="subtitle"></h2>
            <div className="media" style={{ marginBottom: '3rem' }}>
              <div className="media-left">
                <figure className="image is-48x48">
                  <img
                      className="is-rounded"
                      src="/img/interview/thumb/saito.jpg"
                  />
                </figure>
              </div>
              <div className="media-content">
                <div className="notification">
                  大学生のうちは、<strong className="has-text-marker">女優として様々なお仕事をいただけるようになること</strong>が目標です。
                  そのために、レッスンや稽古など、努力を怠らずに頑張ります！
                </div>
              </div>
            </div>

            <h1 className="title as-section-title has-text-left has-text-weight-bold">バッグの中からお気に入りのアイテムを教えて下さい。</h1>
            <h2 className="subtitle"></h2>
            <figure className="image">
              <img src="/img/interview/bag/saito.jpg" />
            </figure>
            <p className="subtitle" style={{ marginTop: '1rem' }}>
              「ロクシタン」のハンドクリーム
            </p>
            <div className="media">
              <div className="media-left">
                <figure className="image is-48x48">
                  <img
                      className="is-rounded"
                      src="/img/interview/thumb/saito.jpg"
                  />
                </figure>
              </div>
              <div className="media-content">
                <div className="notification">
                  プレゼントでいただいたハンドクリームは、ロクシタンのもの。
                  <strong className="has-text-marker">香りも良し、しっとりつけ心地も良し。とても気に入ってます。</strong>
                </div>
              </div>
            </div>
            <div className="columns" style={{marginTop: "1rem"}}>
              <div className="column">
                <div className="box">
                  <article className="media">
                    <div className="media-content">
                      <div className="content" style={{overflow: "hidden"}}>
                        <p>
                          <small>2019年準グランプリ</small>
                          <br />
                          <strong>斎藤渚 (Saito Nagisa)</strong>
                          <br />
                          <small>'98.05.16 東京都出身</small>
                          <br />
                          <small>中央大学 総合政策学部</small>
                          <br />
                          ドラマや舞台など、数多くのメディアにて活躍中。
                        </p>
                        <div className="buttons is-right">
                          <Link className="button is-small" href="/interview/nagisa-saito">特別インタビュー記事を見る</Link>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>

          </div>
        </section>
      </>
  );
};
const InterviewBagOfMachiApp = ({}) => {
  return (
      <>
        <Helmet>
          <meta
              content={`【2019年ミスサークル】準グランプリ町遥香さんのバッグの中身を大公開 👜 👀 | ${process.env.REACT_APP_WEBSITE_NAME}`}
              property="og:title"
          />
          <meta content={`${process.env.REACT_APP_URL}/img/interview/bag/machi-hero.jpg`} property="og:image" />
          <meta content={`${process.env.REACT_APP_URL}/interview/bag/haruka-machi`} property="og:url" />
        </Helmet>
        <ArticleHeroSection src="/img/interview/bag/machi-hero.jpg" />

        <section className="section" style={{ paddingTop: '2rem', paddingBottom: 0 }}>
          <div className="container" style={{ maxWidth: 640 }}>
            <div className="tags" style={{ marginBottom: 0 }}>
              <p className="tag is-primary has-text-weight-bold">Special Contents</p>
            </div>
            <h1 className="title" style={{ lineHeight: 1.5 }}>
              【2019年ミスサークル】
              <br />
              準グランプリ町遥香さんに聞いてみた！バッグの中身も大公開👜 👀
            </h1>
            <div className="notification">
              2019年ミスサークルの町遥香さんに突撃インタビュー🎤
              <br />
              現在ハマっていることや、大学生活の目標について伺いました。
              さらにバッグの中からお気に入りのアイテムも大公開！👜 ✨
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container" style={{ maxWidth: 640 }}>
            <h1 className="title as-section-title has-text-left has-text-weight-bold">
              現在ハマっていることはありますか？
            </h1>
            <h2 className="subtitle"></h2>
            <div className="media" style={{ marginBottom: '3rem' }}>
              <div className="media-left">
                <figure className="image is-48x48">
                  <img
                      className="is-rounded"
                      src="/img/interview/thumb/machi.jpg"
                  />
                </figure>
              </div>
              <div className="media-content">
                <div className="notification">
                  <strong className="has-text-marker">歌とダンスです。</strong>どちらも苦手だったので克服したいと思ったのがきっかけです。
                </div>
              </div>
            </div>

            <h1 className="title as-section-title has-text-left has-text-weight-bold">
              大学生の間にやりたいこと、目標はありますか？
            </h1>
            <h2 className="subtitle"></h2>
            <div className="media" style={{ marginBottom: '3rem' }}>
              <div className="media-left">
                <figure className="image is-48x48">
                  <img
                      className="is-rounded"
                      src="/img/interview/thumb/machi.jpg"
                  />
                </figure>
              </div>
              <div className="media-content">
                <div className="notification">
                  たくさんあります。バンジージャンプ、韓国留学、写真…など。
                  <strong className="has-text-marker">やりたいこと、やらなきゃいけないことを全てやりきって笑顔で卒業したいです。</strong>
                </div>
              </div>
            </div>

            <h1 className="title as-section-title has-text-left has-text-weight-bold">バッグの中からお気に入りのアイテムを教えて下さい。</h1>
            <h2 className="subtitle"></h2>
            <figure className="image">
              <img src="/img/interview/bag/machi.jpg" />
            </figure>
            <p className="subtitle" style={{ marginTop: '1rem' }}>
              「CHANEL」のコンパクトミラー
            </p>
            <div className="media">
              <div className="media-left">
                <figure className="image is-48x48">
                  <img
                      className="is-rounded"
                      src="/img/interview/thumb/machi.jpg"
                  />
                </figure>
              </div>
              <div className="media-content">
                <div className="notification">
                  いただいたCHANELのミラーは、<strong className="has-text-marker">私の名前が刻印入りのもの。</strong>カバンの中でかさばらず、いつも持ち歩いています！
                </div>
              </div>
            </div>
            <div className="columns" style={{marginTop: "1rem"}}>
              <div className="column">
                <div className="box">
                  <article className="media">
                    <div className="media-content">
                      <div className="content" style={{overflow: "hidden"}}>
                        <p>
                          <small>2019年準グランプリ</small>
                          <br />
                          <strong>町遥香 (Machi Haruka)</strong>
                          <br />
                          <small>'98.06.01 埼玉県出身</small>
                          <br />
                          <small>学習院大学 文学部</small>
                          <br />
                          ミスコンで磨いた発信力を活かし、同世代向けのインフルエンサーとして活躍を目指す。
                        </p>
                        <div className="buttons is-right">
                          <Link className="button is-small" href="/interview/haruka-machi">特別インタビュー記事を見る</Link>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
  );
};

